<template>
  <div v-if="modal" class="modal-container">
    <div class="modal-content">
      <img src="../assets/img/cross.svg" alt="close" class="close-button" @click="(status === 'success') ? refreshApp() : (modal = false)">

      <div v-if="status === 'pendingConfirmation'" class="model-content-wrapper">
        <h2 class="space-bottom">
          {{ getTexts('leadDeclineHeader') }}
        </h2>
        <p class="space-bottom">{{ app.lastName ? `${app.lastName} ${app.firstName}` : app.firstName }} ({{ app[UserViewMixin_appIdentifier] }})?</p>
        <div v-if="getRejectText" class="space-bottom">
          <p class="text-bold">{{ getTexts('rejectReason') }}:</p>
          {{ getRejectText }}
        </div>
        <button class="red-button" :disabled="processingReq" @click="decline()"> {{ getButtonLabel('declineAppBtn') }} </button>
        <button class="red-button-alt" @click="modal = false"> {{ getButtonLabel('cancelBtn') }} </button>
      </div>

      <div v-else-if="status === 'conflict'">
        <h2 class="space-bottom">{{ getTexts('concurrencyHeader') }}</h2>
        <p class="space-bottom"> {{ getTexts('concurrencyError') }} </p>
        <button class="green-button" @click="refreshApp()">{{ getButtonLabel('refreshBtn') }}</button>
      </div>

      <div v-else-if="status === 'error'">
        <h2 class="space-bottom">{{ getTexts('errorHeader') }}</h2>
        <p class="space-bottom">{{ statusMessage }}</p>
        <button class="red-button" @click="modal = false">{{ getButtonLabel('closeBtn') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import partnerPortalAPI from '../mixins/apiServices/partnerPortal';
import offerPanelMixin from '../mixins/offerPanelMixin';
import userViewSettingsMixin from '../mixins/userViewSettingsMixin';
import EventBus from '../js/eventBus';

import { useAppStore } from '../store/app';

export default {
  mixins: [partnerPortalAPI, offerPanelMixin, userViewSettingsMixin],
  data  () {
    return {
      modal: false,
      isBlacklisted: false,
      status: 'pendingConfirmation',
      statusMessage: '',
      rejectReason: undefined,
      rejectTxt: undefined,
      rejectReasonOther: '',
      internalRejectReason: '',
      newApps: {},
      appId: '',
      panel: undefined,
      appStore: useAppStore(),
    }
  },
  computed: {
    app () {
      const { currentProduct, apps, currentTab } = this.appStore;
      const currentAppList = apps[currentProduct][currentTab];
      return currentAppList.find(a => a._id === this.appId);
    },
    getRejectText () {
      if (this.rejectReason && typeof this.rejectReason === 'object') {
        const { category, subCategory, remark } = this.rejectReason;
        let categoryText;
        let subCategoryText;

        if (typeof this.rejectTxt === 'object') {
          const { categoryCopy, subCategoryCopy } = this.rejectTxt;
          categoryText = categoryCopy.text;
          subCategoryText = subCategoryCopy.text;
        }
        const fullRejectText = (remark && category === 'lender_policy' && subCategory === 'others') ? `${categoryText}: ${subCategoryText} - ${remark}` : `${categoryText}: ${subCategoryText}`;
        return fullRejectText
      } else {
        return (this.rejectReason === 'other') ? `${this.rejectReason} - ${this.rejectReasonOther}` : this.rejectTxt;
      }
    },
  },
  mounted () {
    EventBus.$on('decline-app', this.declineAppEvent);
  },
  unmounted () {
    EventBus.$off('decline-app', this.declineAppEvent);
  },
  methods: {
    async decline () {
      this.processingReq = true;
      if (this.internalRejectReason === 'undefined') this.internalRejectReason = undefined;

      const reason = (typeof this.rejectReason === 'object') ? this.rejectReason : this.internalRejectReason;

      await this.declineApp(this.appId, this.currentProduct, reason, this.panel, this.isBlacklisted).then(({ responseCode, data }) => {
        if ((responseCode === 200 || responseCode === 409) && Object.keys(data)[0] === this.currentProduct) {
          this.newApps = data;
          if (responseCode === 200) {
            this.processingReq = false;
            return this.refreshApp();
          } else if (responseCode === 409) {
            this.status = 'conflict';
            this.processingReq = false;
            return;
          }
        }

        // if error
        this.status = 'error';
        this.statusMessage = data;
        this.processingReq = false;
      });
    },
    refreshApp () {
      EventBus.$emit('refreshApp', this.newApps);
      this.modal = false;
    },

    declineAppEvent (payload) {
      this.modal = true;
      this.appId = payload.appId;
      this.rejectReason = payload.reason;
      this.rejectReasonOther = payload.otherReason;
      this.rejectTxt = payload.reasonTxt;
      this.internalRejectReason = payload.internalRejectReason;
      this.panel = payload.panel;
      this.isBlacklisted = payload.isBlacklisted;
    }
  }
};
</script>
